.logo-holder {
  padding: 30px 0;
  width: 100%;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  & .cybellum-sign {
    margin: 0 auto;
    width: 180px;
    height: 30px;
    background: url('../../assets/images/logo/cybellum-sign-white.svg') no-repeat center;
    background-size: contain;
    transition: all ease-in-out 0.3s;
  }
  & .cybellum-name {
    margin: 15px auto 0 auto;
    width: 180px;
    height: 30px;
    background: url('../../assets/images/logo/cybellum-name-white.svg') no-repeat center;
    background-size: contain;
    transition: all ease-in-out 0.3s;
  }
}