.upload-box {
  position: relative;
  background-color: white;
  width: 408px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--box-shadow-input);
  text-align: left;
  min-height: 68px;
  .upload-title {
    display: inline-block;
    font-size: 20px;
    color: var(--color-text-dark);
    .upload-percentage {
      font-size: 16px;
    }
    .upload-failure {
      display: flex;
      letter-spacing: 0.44px;
      color: var(--color-error);
      i.cb-icons-error {
        font-size: 16px;
      }
    }
    .upload-failure-subtitle {
      margin-top: 8px;
      font-size: 16px;
      color: var(--color-error);
    }
  }
  .upload-subtitle {
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text);
  }
  .upload-remove {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    i {
      font-size: 18px;
      color: var(--color-error);
    }
  }
}
