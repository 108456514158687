.layout-container {
  .layout-left {
    display: inline-block;
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    background: url("../../assets/images/bg-left.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 24px;
  }
}
