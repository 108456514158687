.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.hide-visibility {
  visibility: hidden !important;
}
