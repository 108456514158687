.table-container {
  .toolbar {
    padding-left: 25px;
    padding-right: 0;
  }

  .toolbar-selected {
    background-color: var(--color-gray-progress);
  }

  th.table-head span {
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.4375px;
    color: var(--color-gray-light);
  }

  .table-row {
    border-radius: 3px;
    background: var(--color-table-background);
    border: 1px solid var(--color-border-table);
    box-shadow: 0 0 7px 0 var(--color-light-shadow);
    transition: background ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s, opacity ease-in-out 0.2s;
    height: 62px;
  }

  .MuiTableRow-root.Mui-selected {
    background: var(--color-gray-progress);
  }
  .MuiTableRow-root.Mui-selected:hover {
    background: var(--color-gray-progress);
  }

  .MuiCheckbox-root.Mui-checked {
    color: var(--color-gold);
  }

  .MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: var(--color-gold);
  }

  // padding on the first cell
  .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:first-child,
  .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: 8px;
  }
  .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: var(--color-text);
  }
}
