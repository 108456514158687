@import "./assets/styles/common.scss";
@import "./assets/styles/colors.scss";
@import "./assets/styles/shadows.scss";

:root {
  --font-montserrat: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --toastify-toast-width: 500px;
}

body {
  color: var(--color-text-dark);
  font-size: 16px;
  margin: 0;
  font-family: var(--font-montserrat);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  margin: 0;
  color: var(--color-text-dark);
  letter-spacing: -0.5px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
}
p {
  color: var(--color-text);
  margin: 8px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
a, a:visited {
  color: var(--color-text);
  text-decoration: none;
}
a:hover {
  opacity: 80%;
}

ul {
  padding-inline-start: 18px;
  li {
    font-size: 14px;
    color: var(--color-text);
  }
  li:not(:first-child) {
    margin-top: 8px;
  }
}