:root {
  --color-text: #6c6c6c;
  --color-text-dark: #343434;
  --color-error: #FF0258;
  --color-dark-background: #090909;
  --color-light-background: #F6F6F6;
  --color-input-border: #D3D4D6;
  --color-gray: #A5ABB1;
  --color-gray-light: #aeacaf;
  --color-gray-progress: #E7E7E7;
  --color-gold: #B29A6A;
  --color-gold-transparent: rgba(178, 154, 106, 0.1);



  --color-dark-selected: #f9a187;
  --color-border-table: #f6f6f6;
  --color-table-background: #fbfbfb;
  --color-light-shadow: rgba(0, 0, 0, 0.3);
  
  --box-shadow-input: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px;

  --background-button: linear-gradient(180deg, #48596B 0%, #232D39 100%);
}